import localStorage from "local-storage";
import Cookies from "js-cookie";

import constants from "constants/index";

// JIT function so that we can override
// constants dynamically in tests
const getCookiesConfig = () => ({
  domain: constants.COOKIE_DOMAIN,
  secure: constants.COOKIE_SECURE,
  expires: 365 * 20,
});

class Storage {
  static setItem(key, value, options = {}) {
    return new Promise((resolve) => {
      if (options.crossDomain) {
        return resolve(Cookies.set(key, value, getCookiesConfig()));
      }

      return resolve(localStorage.set(key, value));
    });
  }

  static setItemSync(key, value, options = {}) {
    if (options.crossDomain) {
      return Cookies.set(key, value, getCookiesConfig());
    }

    return localStorage.set(key, value);
  }

  static getItem(key, options = {}) {
    return new Promise((resolve) => {
      if (options.crossDomain) {
        return resolve(Cookies.get(key, getCookiesConfig()));
      }

      return resolve(localStorage.get(key));
    });
  }

  static removeItem(key, options = {}) {
    return new Promise((resolve) => {
      if (options.crossDomain) {
        return resolve(Cookies.remove(key, getCookiesConfig()));
      }

      return resolve(localStorage.remove(key));
    });
  }
}

export default Storage;

const getEnvVar = (property, value) => {
  if (typeof window !== "undefined") {
    window.__test_config__ = window.__test_config__ || {}; // eslint-disable-line

    if (!(typeof window.__test_config__[property] === "undefined")) {
      // eslint-disable-line
      return window.__test_config__[property]; // eslint-disable-line
    }
  }

  return value;
};

export default {
  get API_PATH() {
    return getEnvVar("GATSBY_API_PATH", process.env.GATSBY_API_PATH);
  },
  get APP_DOMAIN() {
    return getEnvVar("GATSBY_APP_DOMAIN", process.env.GATSBY_APP_DOMAIN);
  },
  get GRAPHQL_PATH() {
    return getEnvVar("GATSBY_GRAPHQL_PATH", process.env.GATSBY_GRAPHQL_PATH);
  },
  get GOOGLE_ANALYTICS_ID() {
    return getEnvVar(
      "GATSBY_GOOGLE_ANALYTICS_ID",
      process.env.GATSBY_GOOGLE_ANALYTICS_ID
    );
  },
  get SENTRY_URL() {
    return getEnvVar("GATSBY_SENTRY_URL", process.env.GATSBY_SENTRY_URL);
  },
  get SENTRY_ENVIRONMENT() {
    return getEnvVar(
      "GATSBY_SENTRY_ENVIRONMENT",
      process.env.GATSBY_SENTRY_ENVIRONMENT
    );
  },
  get SENTRY_RELEASE() {
    return getEnvVar(
      "GATSBY_UIZARD_DEPLOYED_VERSION",
      process.env.GATSBY_UIZARD_DEPLOYED_VERSION
    );
  },
  get COOKIE_DOMAIN() {
    return getEnvVar("GATSBY_COOKIE_DOMAIN", process.env.GATSBY_COOKIE_DOMAIN);
  },
  get COOKIE_SECURE() {
    return (
      getEnvVar("GATSBY_COOKIE_SECURE", process.env.GATSBY_COOKIE_SECURE) ===
      "true"
    );
  },
  get RECAPTCHA_SITE_KEY() {
    return (
      getEnvVar("GATSBY_RECAPTCHA_SITE_KEY", process.env.GATSBY_RECAPTCHA_SITE_KEY)
    );
  },
};
